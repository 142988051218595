import { AfterViewChecked, Component, ElementRef } from '@angular/core';
import { LOG_LEVEL, setAppHeight } from '@amc-technology/davinci-api';

import { LoggerService } from './logger.service';

@Component({
  selector: 'app-root',
  templateUrl: './sap.component.html',
  styleUrls: ['./sap.component.css']
})

export class SapComponent implements AfterViewChecked {
  height: number;
  heightNonZero: boolean;
  el: ElementRef;

  constructor(
    el: ElementRef,
    private loggerService: LoggerService
    ) {
    this.height = 500;
    this.heightNonZero = false;
    this.el = el;
  }

  ngAfterViewChecked(): void {
    this.setHeight();
  }

  private setHeight(): void {
    const fname = 'setHeight';
    try {
      const newHeight = this.getHeight();
      this.loggerService.log(LOG_LEVEL.Loop, fname, `newHeight: ${newHeight}\ncurrentHeight: ${this.height}\nheightNonZero: ${this.heightNonZero}`);
      if (this.heightNonZero && newHeight !== 5) {
        if (newHeight !== this.height) {
          this.height = newHeight;
          setAppHeight(this.height);
        }
      }
    } catch (error) {
      this.loggerService.log(LOG_LEVEL.Error, fname, 'Failed to setHeight', error);
    }
  }

  private getHeight(): number {
    if (this.el.nativeElement.children[0].scrollHeight !== 0) {
      this.heightNonZero = true;
    }
    return this.el.nativeElement.children[0].scrollHeight + 5;
  }
}
