export class Util {
  public static CTI_URL_HTTP = 'http://localhost:36729/?';
  public static CTI_URL_HTTPS = 'https://localhost:36731/?';

  public static _UIType: string;
  public static _CID: string;
  public static _HTTPS = false;
  public static _CTIURL = '';
  public static _SAPWorkTop: string;

  public static initURLArguments(window) {
    Util._UIType = Util.urlArgumentGet(window, 'ui');
    const httpsArg = Util.urlArgumentGet(window, 'https');
    if (httpsArg === 'true') {
      Util._HTTPS = true;
      Util._CTIURL = `${Util.CTI_URL_HTTPS}${Util._CID}&`;
    } else {
      Util._HTTPS = false;
      Util._CTIURL = `${Util.CTI_URL_HTTP}${Util._CID}&`;
    }
  }

  public static urlArgumentGet(window, name): string {
    const url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  public static guidGet(): string {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
      s4() + '-' + s4() + s4() + s4();
  }

  public static httpGet(url: string): void {
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.open('GET', url, true);
    xmlHttp.send(null);
  }
}
